function getSuffix(number) {
  if (number === 1) {
    return ''
  }
  if (number % 10 <= 1 || number % 10 > 4 || (number % 100 > 4 && number % 100 < 20)) {
    return 'ów'
  }
  return 'i'
}

module.exports = getSuffix
