/* eslint-disable react/prefer-stateless-function */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { truncate } from 'lodash'
import SEO from '../components/seo'
import Layout from '../components/Layout'
import NewsSnippet from '../components/NewsSnippet'
import MarkdownContent from '../components/MarkdownContent'
import getSuffix from '../utils/getSuffix'

import '../components/tags.css'

export default function Template({
  data, pageContext, location,
}) {
  const numOfResults = data.allMarkdownRemark.edges.length
  const { edges } = data.allMarkdownRemark
  const offerSnippetCutoff = 200

  return (
    <div>
      <SEO title={pageContext.tag} pathname={location.pathname} />
      <Layout>
        <div className="tags">
          <h1>
            {`Znaleziono ${numOfResults} wynik${getSuffix(numOfResults)}`}
          </h1>
          {edges.map(edge => (
            edge.node.frontmatter.templateKey === 'news-post' ? (
              <NewsSnippet
                key={edge.node.id}
                linkDestination={edge.node.fields.slug}
                image={edge.node.frontmatter.image}
                imgAlt={edge.node.id}
                title={edge.node.frontmatter.title}
                shortDescription={edge.node.frontmatter.short_description}
              />
            ) : (
              <li key={edge.node.id} className="tags__offer">
                <a href={edge.node.fields.slug}>
                  <Img className="tags__offer--thumbnail" fluid={edge.node.frontmatter.picture.childImageSharp.fluid} alt={edge.node.id} />
                </a>
                <div className="tags__offer--right">
                  <h3 className="tags__offer--title">
                    <a href={edge.node.fields.slug}>Język {edge.node.frontmatter.name}</a>
                  </h3>
                  <div className="tags__offer--snippet">
                    <MarkdownContent content={truncate(edge.node.frontmatter.description,
                      {
                        length: offerSnippetCutoff,
                        separator: '/,? +/',
                      })}
                    />
                  </div>
                </div>
              </li>
            )
          ))}
        </div>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
  query PostsByTag($tagRegex: String!) {
    allMarkdownRemark(filter: {frontmatter: {tags: {regex: $tagRegex }}}) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            templateKey
            title
            name
            picture {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            image {
              childImageSharp {
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date(formatString: "DD.MM.YYYY, HH:mm")
            short_description
            description
          }
        }
      }
    }
  }
  `
